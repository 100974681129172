<template>
  <v-row>
    <v-col cols="2">
      <v-select
        v-model="projectId"
        :items="projectList"
        item-text="name"
        item-value="id"
        label="Select Project"
        outlined
        dense
        hide-details
        @change="onProjectChange"
      >
      </v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        v-model="modelId"
        :items="modelList"
        item-text="name"
        item-value="id"
        label="Select Model"
        outlined
        dense
        hide-details
        @change="onModelChange"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import data from "@/models/data";
import _ from "lodash";

export default {
  name: "ModelSelect",
  props: {
    id: {
      type: String,
    },
    datasetGroupId: {
      type: String,
    },
  },
  data: () => ({
    projectId: null,
    modelId: null,
    modelList: [],
    projectList: ["Titanic", "Wine", "Gemstone", "MMM_Demo"],
  }),
  computed: {
    // modelList() {
    //   return this.$store.getters.modelList;
    // },
  },
  methods: {
    async getProjectList() {
      this.$emit("onResetDefaultInfo");
      try {
        let items = await data.getDatasetGroups();
        items = items.data.DatasetGroup;
        const datasetGroupDomains = await data.getDatasetGroupDomains();
        items = _.orderBy(items, ["id"], ["desc"]);
        items.forEach((item) => {
          const domain = datasetGroupDomains.find((domain) => {
            return domain.id === item.domain.id;
          });
          item["domain"] = domain.name;
        });
        this.projectList = items;

        // if we got a datasetGroupId from the url query
        // such as http://xxx.xxx.xx.xx:8080/results?id=10&datasetGroupId=6
        // get data of the datasetGroup by id
        if (this.datasetGroupId !== null) {
          this.projectId = parseInt(this.datasetGroupId);
          this.onProjectChange(this.projectId);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getModelList(datasetGroupId) {
      this.$emit("onResetDefaultInfo");
      try {
        const res = await data.getModels();
        this.modelList = res.data.models.filter((model) => {
          return (
            model.dataset_group_id === datasetGroupId &&
            model.is_done &&
            model.progress === 100
          );
        });
      } catch (err) {
        console.log(err);
      }
      // // Temporary comment MMM results
      // await this.$store.dispatch("getModelList");

      // if we got a model id from the url query
      // such as http://xxx.xxx.xx.xx:8080/results?id=10
      // get data of the model by id
      if (this.id !== null) {
        this.modelId = parseInt(this.id);
        this.onModelChange(this.modelId);
      }
    },
    async onProjectChange(datasetGroupId) {
      const item = this.projectList.filter((o) => {
        return o.id === datasetGroupId;
      });
      this.$emit("onSelectDatasetGroup", item[0]);
      await this.getModelList(datasetGroupId);
    },
    async onModelChange(modelId) {
      // tell results page to hide defulat info

      const item = this.modelList.filter((o) => {
        return o.id === modelId;
      });

      const type = item[0].type;
      if (type === "mmm") {
        this.$store.commit("UPDATE_MODEL_ID", this.modelId);
        await this.$store.dispatch("onChangeModelID");
      }
      this.$emit("onTypeChange", type);
      this.$emit("onModelChange");

      // if (true) {
      //   console.log("70");
      // } else {
      //   // update model id in the store
      //   this.$store.commit("UPDATE_MODEL_ID", this.modelId);

      //   // get data of the model by id
      //   await this.$store.dispatch("onChangeModelID");
      // }
    },
  },
  async created() {
    await this.getProjectList();
    // await this.getModelList();
  },
};
</script>

<style scoped></style>
